// Fix billing field wrapper
.woocommerce-input-wrapper {
	width: 100%;
}

// Woocommerce product gallery slider width fix
figure.woocommerce-product-gallery__wrapper {
	max-width: inherit !important;
}

// Fix coupon code input width
@media(min-width: 768px) {
	#coupon_code.input-text {
		width: 110px !important;
	}
}
